import * as React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="contact mb-3">
        <h2 className="title mt-5"><b>Contactgegevens</b></h2>
        <span>Hans Haardenservice</span>
        <a href="mailto:info@hanshaardenservice.be">info@hanshaardenservice.be</a>
        <a href="tel:+32470971788">+32 470 97 17 88</a>
        <a href="tel:+3215634211">+32 15 63 42 11</a>
        <span>Ondernemingsnummer BE 0797 411 462</span>
        <a href="./pdf/privacyverklaring.pdf">Privacyverklaring</a>
      </footer>
    );
  }
};

export default Footer;
