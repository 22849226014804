import * as React from "react";
import PropTypes from "prop-types";
import { Link } from 'gatsby'

const AfspraakBtn = () => (
  <Link className="afspraak-button" to="/afspraak" title="Maak een afspraak">
    Maak een afspraak <Haard />
  </Link>
);

AfspraakBtn.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default AfspraakBtn;

const Haard = (props) => (
  <svg className="haard" viewBox="0 0 548 748" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(4.16667,0,0,4.16667,-1258.33,-1541.67)">
          <g transform="matrix(1.99265,0,0,-1.99265,268.086,559.301)">
              <path d="M71.16,34.925C75.824,40.323 67.836,56.835 73.07,61.027C66.914,60.601 66.914,53.703 64.844,53.394C62.773,53.09 62.785,56.285 63.09,60.386C63.726,68.879 60.758,73.765 53.324,74.827C55.965,72.054 56.023,63.534 49.644,66.191C44.551,68.316 51.414,80.984 44.836,95C45.211,86.582 33.578,81.621 34.641,66.121C35.11,59.308 37.43,54.519 36.059,53.578C32.129,50.875 28.024,61.554 29.121,65.48C26.778,62.78 21.438,57.573 25.723,49.964C28.078,45.776 29.051,39.558 27.106,39.679C25.512,39.784 21.266,44.776 17.02,41.487C23.578,38.761 15.051,8.042 44.348,4.999C37.617,9.238 37.77,20.46 36.414,20.608C41.016,21.526 41.277,17.089 43.066,16.644C45.402,17.069 40.031,21.659 43.852,26.725C43.77,23.901 47.59,17.952 50,18.698C52.328,19.417 45.152,31.19 51.883,36.327C50,31.3 56.937,24.819 58.355,16.147C58.742,20.042 59.91,22.167 63.945,20.893C61.273,20.085 62.273,9.752 56.609,5.358C83.523,10.093 78.757,41.03 82.984,41.491C75.789,43.894 76.07,36.148 71.16,34.925L71.16,34.925ZM21.699,32.101C21.699,32.101 21.441,38.663 18.164,41.132C18.164,41.132 22.722,39.769 22.937,35.89C23.144,32.015 21.699,32.101 21.699,32.101L21.699,32.101ZM37.761,68.144C38.644,62.425 38.781,57.949 37.761,56.382C37.335,56.007 35.909,62.335 35.566,67.327C34.667,80.472 45.042,86.292 45.042,92.257C46.878,84.421 36.058,79.136 37.761,68.144L37.761,68.144Z" 
              style={{fill: (props.color ? props.color : '#FFFFFF'), fillRule: 'nonzero'}}/>
          </g>
      </g>
  </svg>
);
  