import React from 'react'
import logo from "../../static/img/logo_svg.svg";
import { Link } from 'gatsby'
import AfspraakBtn from '../components/AfspraakBtn';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-logo" to="/" title="Home">
            <img src={logo} alt="Logo" width="160" className="d-inline-block align-text-top" />
          </Link>
          <button className="navbar-toggler"
            type="button" data-toggle="collapse"
            data-target="#navbar" 
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"
            onClick={this.toggleHamburger}
            >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={(this.state.active ? '' : 'collapse') + ' navbar-collapse justify-content-md-center'} id="navbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" title="Home">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/onderhoud" className="nav-link" title="Onderhoud">Onderhoud</Link>
              </li>
              <li className="nav-item">
                <Link to="/reparaties-storingen" className="nav-link" title="Reparaties & Storingen">Reparaties & Storingen</Link>
              </li>
              <li className="nav-item afspraak-nav-item">
                <Link to="/afspraak" className="nav-link" title="Afpsraak">Maak een afspraak</Link>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noopener noreferrer"
                    href={this.props.contactgegevens.facebook} title="facebook">
                      <img src={facebook} alt="facebook" height="27" className="facebook"/>
                </a>
              </li> */}
            </ul>
            
          </div>
          <div className="nav-afspraak-btn">
            <AfspraakBtn />
          </div>
          
        </div>
      </nav>
    )
  }
}

export default Navbar
